import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "prefetching"
    }}><a parentName="h1" {...{
        "href": "#prefetching",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Prefetching`}</h1>
    <p>{`Loadable Components is fully compatible with `}<a parentName="p" {...{
        "href": "https://webpack.js.org/guides/code-splitting/#prefetching-preloading-modules"
      }}>{`webpack hints `}<inlineCode parentName="a">{`webpackPrefetch`}</inlineCode>{` and `}<inlineCode parentName="a">{`webpackPreload`}</inlineCode></a>{`.`}</p>
    <p>{`Most of the time, you want to "prefetch" a component, it means it will be loaded when the browser is idle. You can do it by adding `}<inlineCode parentName="p">{`/* webpackPrefetch: true */`}</inlineCode>{` inside your import statement.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const OtherComponent = loadable(() =>
  import(/* webpackPrefetch: true */ './OtherComponent'),
)
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`You can extract prefetched resources server-side to add `}<inlineCode parentName="p">{`<link rel="prefetch">`}</inlineCode>{` in your head.`}</p>
    </blockquote>
    <h2 {...{
      "id": "manually-preload-a-component"
    }}><a parentName="h2" {...{
        "href": "#manually-preload-a-component",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Manually preload a component`}</h2>
    <p>{`It is possible to `}<em parentName="p">{`force`}</em>{` the preload of a component. It has the same effect as if the component is rendered for the first time.`}</p>
    <p>{`It can be useful to trigger a `}<inlineCode parentName="p">{`preload`}</inlineCode>{` on mouse over:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const Infos = loadable(() => import('./Infos'))

function App() {
  const [show, setShow] = useState(false)
  return (
    <div>
      <a onMouseOver={() => Infos.preload()} onClick={() => setShow(true)}>
        Show Infos
      </a>
      {show && <Infos />}
    </div>
  )
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`preload`}</inlineCode>{` is not available server-side, you should only call it client-side. If you want to use prefetching server-side, use webpack hints instead.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`preload`}</inlineCode>{` is aggressive and doesn't take care of network condition and data saving preference of the user. You should call it carefully.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      